<template>
  <div>
    <full-page ref="fullpage" :options="options">
      <div v-for="i in urlData" :key="i.url" class="section">
        <div :class="i.p6 ? 'p-6' : ''">
          <img :src="i.url" />
          <a href="../experience" class="experience" v-if="i.p6!=true">试用体验</a>
        </div>
      </div>
      <div class="section">
        <div class="box7">
          <Footer></Footer>
        </div>
      </div>
    </full-page>
     <div class="sidebar4 flex" @click="backTop">
      <img src="../../assets/images/sidebar4.png" />
    </div>
  </div>
</template>

<script>
import Footer from "../../layout/components/footer.vue";
// import { BusEmit } from "@/utils/eventBus.js";
export default {
  name: "",
  components: { Footer },
  methods: {
    // handc() {
    //   BusEmit("Top", "setBlackBg");
    // },
    // setWhite() {
    //   BusEmit("Top", "setWhiteBg");
    // },
    // afterLoad(item, index) {
    //   let page = index.index;
    //   if (page == 7) {
    //     this.setWhite();
    //   } else {
    //     this.handc();
    //   }
    // }
       backTop() {
      this.$refs.fullpage.api.moveTo(1, 0)
    }
  },
  data() {
    return {
      urlData: [
        {
          url: require("@/assets/1.jpg")
        },
        {
          url: require("@/assets/2.jpg"),
          p6: true
        },
        {
          url: require("@/assets/3.jpg"),
          p6: true
        },
        {
          url: require("@/assets/4.jpg"),
          p6: true
        },
        {
          url: require("@/assets/5.jpg"),
          p6: true
        },
        {
          url: require("@/assets/6.jpg"),
          p6: true
        }
      ],
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollBar: false,
        menu: "#menu",
        // navigation: true,
        // anchors: ['page1', 'page2', 'page3'],
        // sectionsColor: ["", "#FFF", "#252a34", "#FFF", "#4656b5", "#FFF", ""]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 1920px;
  img {
    width: 100vw;
    height: 100vh;
  }
  .p-6 img {
    height: calc(100vh - 60px);
  }
  .experience {
        display: inline-block;
        width: 200px;
        height: 54px;
        text-decoration: none;
        background-color: #fff;
        text-align: center;
        line-height: 54px;
        border-radius: 27px;
        color: #3451ff;
        font-size: 22px;
        position: absolute;
        top: 6.5%;
        right: 13%;
      }
      .experience:hover {
        background-color: $blue;
        color: #fff;
      }
}
.sidebar4 {
  position: fixed;
  z-index: 1000;
  width: 50px;
  right: 60px;
  bottom: 66px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 50%;
  background-color: $blue;
  border: 2px solid #fff;
  cursor: pointer;
}
</style>
